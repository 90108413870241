import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


const gltfLoader = new GLTFLoader()
const rgbeLoader = new RGBELoader()
const textureLoader = new THREE.TextureLoader()

//audio
const audioLoader = new THREE.AudioLoader()
const audioListener = new THREE.AudioListener()

const typeSounds = [];
const audioFiles = ['sounds/typesound1.mp3', 'sounds/typesound2.mp3', 'sounds/typesound3.mp3', 'sounds/typesound4.mp3',
  , 'sounds/typesound5.mp3', 'sounds/typesound6.mp3', 'sounds/typesound7.mp3', 'sounds/typesound8.mp3', 'sounds/typesound9.mp3',
  'sounds/typesound10.mp3', 'sounds/typesound11.mp3', 'sounds/typesound12.mp3', 'sounds/typesound13.mp3', 'sounds/typesound14.mp3'];

//audio random
audioFiles.forEach((file) => {
  const sound = new THREE.Audio(audioListener);
  audioLoader.load(file, (audioBuffer) => {
    sound.setBuffer(audioBuffer);
    typeSounds.push(sound);
  });
});

function playRandomSound() {
  if (typeSounds.length > 0) {
    const randomIndex = Math.floor(Math.random() * typeSounds.length);
    typeSounds[randomIndex].play();
  }
}

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster()
let currentIntersect = null
const rayOrigin = new THREE.Vector3(- 3, 0, 0)
const rayDirection = new THREE.Vector3(0, 0, 0)
rayDirection.normalize()

// raycaster.set(rayOrigin, rayDirection)
const mouse = new THREE.Vector2()

window.addEventListener('mousemove', (event) => {
  mouse.x = event.clientX / sizes.width * 2 - 1
  mouse.y = - (event.clientY / sizes.height) * 2 + 1
})

window.addEventListener('click', () => {
  // Cast a ray from the mouse position
  raycaster.setFromCamera(mouse, camera);

  // Check for intersections with group4
  const intersects = raycaster.intersectObjects(group4.children);

  if (intersects.length > 0) {
    // If there are intersections, play a random sound
    playRandomSound();
  } else {
    // console.log("dodo");
  }
});


/**
 * Textures
 */
const matcapTexture = textureLoader.load('textures/matcaps/31.png') //nol //white
const matcapTexture2 = textureLoader.load('textures/matcaps/24.png')//dang //white grey 3 23 24 
const matcapTexture3 = textureLoader.load('textures/matcaps/37.png')//gab//black 
const matcapTexture4 = textureLoader.load('textures/matcaps/32.png')//seo//grey black 26 28 32
const matcapTexture5 = textureLoader.load('textures/matcaps/29.png')//yang //grey 11 3 26 29

//land
const matcapTexture6 = textureLoader.load('textures/matcaps/37.png') //nol //black 
const matcapTexture7 = textureLoader.load('textures/matcaps/33.png') //dang//grey black 
const matcapTexture8 = textureLoader.load('textures/matcaps/28.png') //yang //grey 
const matcapTexture9 = textureLoader.load('textures/matcaps/14.png') //seo //white grey 30
const matcapTexture10 = textureLoader.load('textures/matcaps/30.png') //gab //white 

const matcapTexture11 = textureLoader.load('textures/matcaps/26.png') //silver 27
const matcapTexture12 = textureLoader.load('textures/matcaps/26.png') //silver red


matcapTexture.colorSpace = THREE.SRGBColorSpace

// /**
//  * Update all materials
//  */
// const updateAllMaterials = () => {
//   scene.traverse((child) => {
//     if (child.isMesh) {
//       child.castShadow = true
//       child.receiveShadow = true
//     }
//   })
// }

// /**
//  * Environment map
//  */
// // Intensity
// scene.environmentIntensity = 1

// // HDR (RGBE) equirectangular
// rgbeLoader.load('textures/2k.hdr', (environmentMap) =>
// {
//     environmentMap.mapping = THREE.EquirectangularReflectionMapping

//     scene.background = environmentMap
//     scene.environment = environmentMap
// })

//gltfloader
const group = new THREE.Group(); //type
const group2 = new THREE.Group(); //land 
const group3 = new THREE.Group(); //type silver
const group4 = new THREE.Group(); //type silver eng
const groupall = new THREE.Group();

//type
gltfLoader.load(
  '/models/gltf/type/noljoytype.gltf',
  (gltf) => {
    gltf.scene.scale.set(1, 1, 1)
    // scene.add(gltf.scene)

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
    // updateAllMaterials()
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });
    group.add(gltf.scene)
  }
)
gltfLoader.load(
  '/models/gltf/type/dangmadtype.gltf',
  (gltf) => {
    gltf.scene.scale.set(1, 1, 1)
    // scene.add(gltf.scene)

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture2 })
    // updateAllMaterials()
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });
    group.add(gltf.scene)
  }
)
gltfLoader.load(
  '/models/gltf/type/gabsurtype.gltf',
  (gltf) => {
    gltf.scene.scale.set(1, 1, 1)
    // scene.add(gltf.scene)

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture3})
    // updateAllMaterials()
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });
    group.add(gltf.scene)
  }
)
gltfLoader.load(
  '/models/gltf/type/seolovetype.gltf',
  (gltf) => {
    gltf.scene.scale.set(1, 1, 1)
    // scene.add(gltf.scene)

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture4 })
    // updateAllMaterials()
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });
    group.add(gltf.scene)
  }
)
gltfLoader.load(
  '/models/gltf/type/yangsadtype.gltf',
  (gltf) => {
    gltf.scene.scale.set(1, 1, 1)
    // scene.add(gltf.scene)

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture5 })
    // updateAllMaterials()
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });
    group.add(gltf.scene)
  }
)

//land
gltfLoader.load(
  '/models/gltf/land/noljoyland.gltf',
  (gltf) => {
    gltf.scene.scale.set(1, 1, 1)
    // scene.add(gltf.scene)

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture6 })
    // updateAllMaterials()
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });
    group2.add(gltf.scene)
  }
)
gltfLoader.load(
  '/models/gltf/land/dangmadland.gltf',
  (gltf) => {
    gltf.scene.scale.set(1, 1, 1)
    // scene.add(gltf.scene)

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture})
    // updateAllMaterials()
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });
    group2.add(gltf.scene)
  }
)
gltfLoader.load(
  '/models/gltf/land/gabsurland.gltf',
  (gltf) => {
    gltf.scene.scale.set(1, 1, 1)
    // scene.add(gltf.scene)

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture10 })
    // updateAllMaterials()
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });
    group2.add(gltf.scene)
  }
)
gltfLoader.load(
  '/models/gltf/land/seoloveland.gltf',
  (gltf) => {
    gltf.scene.scale.set(1, 1, 1)
    // scene.add(gltf.scene)

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture9 })
    // updateAllMaterials()
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });
    group2.add(gltf.scene)
  }
)
gltfLoader.load(
  '/models/gltf/land/yangsadland.gltf',
  (gltf) => {
    gltf.scene.scale.set(1, 1, 1)
    // scene.add(gltf.scene)

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture8 })
    // updateAllMaterials()
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
        child.material = material;
      }
    });
    group2.add(gltf.scene)
  }
)

scene.add(group)
group.scale.set(.8, .8, .8)
group.position.set(0, -.55, 0)

scene.add(group2)
group2.scale.set(.82, .82, .82)
group2.position.set(0, -.55, 0)



/**
 * Donuts
 */

// const donutGeometry = new THREE.TorusGeometry(3, 1, 50, 50);
// for (let i = 0; i < 200; i++) {
//   const donut = new THREE.Mesh(donutGeometry, new THREE.MeshMatcapMaterial({
//     matcap: matcapTexture11,
//     transparent: true,    // 투명도 활성화
//     opacity: 1
//   }));
//   donut.position.x = (Math.random() - 0.5) * 30;
//   donut.position.y = (Math.random() - 0.5) * 30;
//   donut.position.z = (Math.random() - 0.5) * 30;
//   donut.rotation.x = Math.random() * Math.PI;
//   donut.rotation.y = Math.random() * Math.PI;
//   const scale = Math.random() * .2;
//   donut.scale.set(scale, scale, scale);

//   scene.add(donut);
// }



/**
 * Fonts
 */
const fontLoader = new FontLoader()



fontLoader.load(
  '/font/helvetiker_regular.typeface.json',
  (font) => {
    // Material
    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture11 })
    const material2 = new THREE.MeshMatcapMaterial({ matcap: matcapTexture12 })
    // joy
    const textGeometry = new TextGeometry(
      'joy',
      {
        font: font,
        size: 0.13,
        depth: 0.03,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.003,
        bevelSize: 0.002,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )
    textGeometry.center()
    const text = new THREE.Mesh(textGeometry, material)
    text.position.set(-.2, -.39, -1.3)

    // madness
    const textGeometry2 = new TextGeometry(
      'madness',
      {
        font: font,
        size: 0.13,
        depth: 0.04,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.003,
        bevelSize: 0.002,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )
    textGeometry.center()
    const text2 = new THREE.Mesh(textGeometry2, material)
    text2.position.set(.41, -.39, -1.1)


    // sadness
    const textGeometry3 = new TextGeometry(
      'sadness',
      {
        font: font,
        size: 0.13,
        depth: 0.04,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.003,
        bevelSize: 0.002,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )
    textGeometry3.center()
    const text3 = new THREE.Mesh(textGeometry3, material)
    text3.position.set(1.1, -.39, .14)
    text3.rotateY(-1.55)


    // love
    const textGeometry4 = new TextGeometry(
      'love',
      {
        font: font,
        size: 0.13,
        depth: 0.04,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.003,
        bevelSize: 0.002,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )
    textGeometry4.center()
    const text4 = new THREE.Mesh(textGeometry4, material)
    text4.position.set(.55, -.39, 1.22)
    text4.rotateY(-3.1)


    // surprise
    const textGeometry5 = new TextGeometry(
      'surprise',
      {
        font: font,
        size: 0.13,
        depth: 0.04,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.003,
        bevelSize: 0.002,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )
    textGeometry5.center()
    const text5 = new THREE.Mesh(textGeometry5, material)
    text5.position.set(-1.2, -.39, -.15)
    text5.rotateY(1.6)

    scene.add(text, text2, text3, text4, text5)


    // main text
    const textGeometry6 = new TextGeometry(
      'Sound Carved',
      {
        font: font,
        size: 0.2,
        depth: 0.04,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.03,
        bevelSize: 0.004,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )
    textGeometry6.center()
    const text6 = new THREE.Mesh(textGeometry6, material2)
    text6.position.set(0, 1.8, 0)
    scene.add(text6)

    const textGeometry7 = new TextGeometry(
      'by Wind and Water',
      {
        font: font,
        size: 0.2,
        depth: 0.06,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.003,
        bevelSize: 0.004,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )

    const text7 = new THREE.Mesh(textGeometry7, material2)
    textGeometry7.center()
    text7.position.set(0, 1.5, 0)
    scene.add(text7)

    group3.add(text, text2, text3, text4, text5)
    scene.add(group3)

    group4.add(text6, text7)
    scene.add(group4)


  }
)
fontLoader.load(
  '/font/Pretendard.json',
  (font) => {

    const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture11 })

    const textGeometry8 = new TextGeometry(
      'ㅅ',
      {
        font: font,
        size: 50,
        depth: 12,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 1,
        bevelSize: .5,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )


    for (let i = 0; i < 50; i++) {
      const text8 = new THREE.Mesh(textGeometry8, material)
      textGeometry8.center()
      text8.position.x = (Math.random() - 0.5) * 35;
      text8.position.y = (Math.random() - 0.5) * 35;
      text8.position.z = (Math.random() - 0.5) * 35;
      text8.rotation.x = Math.random() * Math.PI;
      text8.rotation.y = Math.random() * Math.PI;
      const scale = Math.random() * .04;
      text8.scale.set(scale, scale, scale);
      scene.add(text8);
    }

    const textGeometry9 = new TextGeometry(
      'ㄹ',
      {
        font: font,
        size: 50,
        depth: 12,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 1,
        bevelSize: .5,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )


    for (let i = 0; i < 50; i++) {
      const text9 = new THREE.Mesh(textGeometry9, material)
      textGeometry9.center()
      text9.position.x = (Math.random() - 0.5) * 35;
      text9.position.y = (Math.random() - 0.5) * 35;
      text9.position.z = (Math.random() - 0.5) * 35;
      text9.rotation.x = Math.random() * Math.PI;
      text9.rotation.y = Math.random() * Math.PI;
      const scale = Math.random() * .04;
      text9.scale.set(scale, scale, scale);
      scene.add(text9);
    }

    const textGeometry10 = new TextGeometry(
      'ㅌ',
      {
        font: font,
        size: 50,
        depth: 12,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 1,
        bevelSize: .5,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )
    for (let i = 0; i < 50; i++) {
      const text10 = new THREE.Mesh(textGeometry10, material)
      textGeometry10.center()
      text10.position.x = (Math.random() - 0.5) * 35;
      text10.position.y = (Math.random() - 0.5) * 35;
      text10.position.z = (Math.random() - 0.5) * 35;
      text10.rotation.x = Math.random() * Math.PI;
      text10.rotation.y = Math.random() * Math.PI;
      const scale = Math.random() * .04;
      text10.scale.set(scale, scale, scale);
      scene.add(text10);
    }


    const textGeometry11 = new TextGeometry(
      'ㅋ',
      {
        font: font,
        size: 50,
        depth: 12,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 1,
        bevelSize: .5,
        bevelOffset: 0,
        bevelSegments: 5
      }
    )
    for (let i = 0; i < 50; i++) {
      const text11 = new THREE.Mesh(textGeometry11, material)
      textGeometry11.center()
      text11.position.x = (Math.random() - 0.5) * 35;
      text11.position.y = (Math.random() - 0.5) * 35;
      text11.position.z = (Math.random() - 0.5) * 35;
      text11.rotation.x = Math.random() * Math.PI;
      text11.rotation.y = Math.random() * Math.PI;
      const scale = Math.random() * .04;
      text11.scale.set(scale, scale, scale);
      scene.add(text11);
    }

  })


//gltf rotate
function gltfRotate() {

  if (group) {
    const rotationSpeed = -0.001; // 회전 속도 (조절 가능)
    group.rotation.y += rotationSpeed;
  }
  if (group2) {
    group2.rotation.y += -.001;
  }
  if (group3) {
    group3.rotation.y += -.001;
  }
  if (group4) {
    group4.rotation.y += .001;
  }
}

/**
 * group all 
 */

groupall.add(group, group2, group4)
scene.add(groupall)
groupall.position.set(0, .4, 0)
group3.position.set(0, .38, 0)



/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  renderer.antialias = true
})

/**
 * Camera
 */
// Base camera

const camera = new THREE.PerspectiveCamera(15, sizes.width / sizes.height, 0.1, 1000)
// camera.position.x = 10
camera.position.y = 0
camera.position.z = 20
scene.add(camera)

camera.add(audioListener)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
// controls.autoRotate = true;
// controls.autoRotateSpeed = 0.5;
controls.rotateSpeed = 1; //OrbitControls로 회전시킬 때 속도 
controls.minDistance = 5; // Minimum distance for zoom
controls.maxDistance = 40; // Maximum distance for zoom
controls.dampingFactor = .05; // Damping factor for smooth rotation and zoom
controls.zoomSpeed = 1; // Adjust this value to control the zoom speed
controls.minPolarAngle = Math.PI / 4;  // 최소 세로 회전 각도 (예: 45도)
controls.maxPolarAngle = Math.PI / 1.5;  // 최대 세로 회전 각도 (예: 120도)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

scene.background = new THREE.Color(0xffffff);
renderer.setClearColor(0XA8A8A8, 1)
// scene.fog = new THREE.FogExp2(0xffffff, 0.01)

/**
 * Animate
 */


// VRButton 생성 및 추가
const vrButton = VRButton.createButton(renderer);
document.body.appendChild(vrButton);

// 생성된 VRButton 요소에 ID와 스타일 적용
vrButton.id = "myVRButton";
vrButton.style.position = 'fixed';
vrButton.style.borderRadius = '30px 30px 30px 30px';
vrButton.style.color = '#94360E';
vrButton.style.lineHeight = '8px';
vrButton.innerText = "VR";
vrButton.style.fontFamily = 'Absans';


//Mouse

const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) => {
  cursor.x = event.clientX / sizes.width - 0.5
  cursor.y = event.clientY / sizes.height - 0.5
})


const clock = new THREE.Clock()
let previousTime = 0
let targetScale = 1;
const scaleSpeed = 0.3; // 스케일 변화 속도

const tick = () => {
  const elapsedTime = clock.getElapsedTime()
  // const deltaTime = elapsedTime - previousTime
  // previousTime = elapsedTime


  // Animate camera
  // camera.position.y = - scrollY / sizes.height 


  // const parallaxX = cursor.x * 5;
  // const parallaxY = - cursor.y * 5;
  // group5.position.x += (parallaxX - group5.position.x) * .5 * deltaTime;
  // group5.position.y += (parallaxY - group5.position.y) * .5 * deltaTime;

  // Cast a ray from the mouse and handle events
  raycaster.setFromCamera(mouse, camera);

  const intersects = raycaster.intersectObjects(group4.children); // group4의 자식만 검사

  if (intersects.length) {
    targetScale = 1.03; // 마우스가 올라가면 목표 스케일을 1.05로 설정
    canvas.style.cursor = 'pointer';
  } else {
    targetScale = 1; // 마우스가 떨어지면 목표 스케일을 1로 설정
    canvas.style.cursor = 'auto';
  }

  // 현재 스케일을 부드럽게 목표 스케일로 변경
  group4.scale.x = THREE.MathUtils.lerp(group4.scale.x, targetScale, scaleSpeed);
  group4.scale.y = THREE.MathUtils.lerp(group4.scale.y, targetScale, scaleSpeed);
  group4.scale.z = THREE.MathUtils.lerp(group4.scale.z, targetScale, scaleSpeed);
  gltfRotate();



  // controls.target.set(3,-1,0);
  // Update controls
  controls.update()

  renderer.xr.enabled = true;
  renderer.xr.setFramebufferScaleFactor(2.0)
  renderer.setAnimationLoop(function () {

    renderer.render(scene, camera);

  });

  // Render
  renderer.render(scene, camera)

  // Call tick again on the next frame
  window.requestAnimationFrame(tick)
}

tick()
